import axios from "axios";

const config = {
  baseUrl: `${process.env.VUE_APP_SERVER_URL}/api/`,
};
const  setAddImageList = (target) => {
  
    let imageList = new Array();
    if(target.img) imageList.push(target.img)
    if(target.img2) imageList.push(target.img2)
    if(target.img3) imageList.push(target.img3)
    if(target.img4) imageList.push(target.img4)
    if(target.img5) imageList.push(target.img5)
    if(target.img6) imageList.push(target.img6)
    return imageList
  }
// 메인화면
// 배너 출력
function banner(code) {
  return axios.get(`${config.baseUrl}main/banner/bannerlist?code=${code}`);
}
// 전체 검색
function totalsearch(code, searchtext) {
  return axios.get(
    `${config.baseUrl}totalsearch?code=${code}&searchtext=${searchtext}`
  );
}
// 게시판 등록갯수 및 최신글
function mainstatics(code) {
  return axios.get(`${config.baseUrl}mainstatics?code=${code}`);
}
// 공지 출력
function mainnotice(page, size, code, searchtext) {
  return axios.get(
    `${config.baseUrl}main/noticelist?page=${page}&size=${size}&code=${code}&searchtext=${searchtext}`
  );
}

// 공지
// list
function notice(page, size, code, searchtext) {
  return axios.get(
    `${config.baseUrl}noticelist?page=${page}&size=${size}&code=${code}&searchtext=${searchtext}`
  );
}
// info
function noticeinfo(id) {
  return axios.get(`${config.baseUrl}noticeinfo?id=${id}`);
}
// 조회수
function noticecount(id, nationcode) {
  return axios.get(
    `${config.baseUrl}notice/updateviewcount?id=${id}&nationcode=${nationcode}`
  );
}

// 관광정보
// list
function tourlist(page, size, code, categoryid, searchtext) {
  return axios.get(
    `${config.baseUrl}tourlist?page=${page}&size=${size}&code=${code}&categoryid=${categoryid}&searchtext=${searchtext}`
  );
}
// info
function tourinfo(phonenumber,nationCode) {
  return axios.get(`${config.baseUrl}tourinfobygroup?groupname=${phonenumber}&code=${nationCode}`).then((item)=>{
    item.data.data.image = setAddImageList(item.data.data)
    return item.data}).catch((e)=>{ });
}

// 의료기관정보
function medicalList(page, size, code, categoryid, searchtext) {
  return axios.get(
    `${config.baseUrl}medicallist?page=${page}&size=${size}&code=${code}&categoryid=${categoryid}&searchtext=${searchtext}`
  );
}
function medicalinfo(phonenumber,nationCode) { 
  return axios.get(`${config.baseUrl}medicalinfobygroup?groupname=${phonenumber}&code=${nationCode}`).then((item)=>
  {
    item.data.data.image = setAddImageList(item.data.data)
     return item.data
  }).catch(e =>{

    });;
}
// 조회수
function medicalcount(id, nationcode) {
  return axios.get(
    `${config.baseUrl}medicalinfo/updateviewcount?id=${id}&nationcode=${nationcode}`
  );
}

// eBook
// list
function eBook(page, size, code, searchtext) {
  return axios.get(
    `${config.baseUrl}eventlist?page=${page}&size=${size}&code=${code}&searchtext=${searchtext}`
  );
}
// info
function eBookinfo(id) {
  return axios.get(`${config.baseUrl}eventinfo?id=${id}`);
}
// 조회수
function eBookcount(id, nationcode) {
  return axios.get(
    `${config.baseUrl}event/updateviewcount?id=${id}&nationcode=${nationcode}`
  );
}

// faq
function faqList(page, code, kindof, searchtext) {
  return axios.get(
    `${config.baseUrl}faqlist?page=${page}&code=${code}&kindof=${kindof}&searchtext=${searchtext}`
  );
}

// 위치기반 지도
function categoryinfo(categorykindof) {
  return axios.get(
    `${config.baseUrl}categoryinfo?categorykindof=${categorykindof}`
  );
}

// 번역
function detectlang(text) {
  return axios.post(`${config.baseUrl}detectlang?text=${text}`);
}
function translate(text, source, target) {
  return axios.post(
    `${config.baseUrl}translate?text=${text}&source=${source}&target=${target}`
  );
}

// 항공운행정보
// 항공편 조회
function airinfo(searchdate, startcitycode, arrvcitycode, isInternational) {
  if(isInternational === 1 ){//국제선
    const searchData = startcitycode ? startcitycode:arrvcitycode
    return axios.get(
      `${config.baseUrl}AirportInfo?searchdate=${searchdate}&startcitycode=${searchData}&arrvcitycode=`
    );
  }else{//국내선
    return axios.get(
      `${config.baseUrl}AirportInfoKor?searchdate=${searchdate}&startcitycode=${startcitycode}&arrvcitycode=${arrvcitycode}`
    );
  }
  
}
// 공항검색
function airsearch(airsearch) {
  return axios.get(
    `${config.baseUrl}AirportCityListByText?searchtext=${airsearch}`
  );
}
function airsearcharr(airsearch) {
  return axios.get(
    `${config.baseUrl}AirportCityListByText?searchtext=${airsearch}`
  );
}

// 기차운행정보
// 열차조회
function traininfo({depPlaceId, arrPlaceId, depPlandTime,traincode}) {
  return axios.post(
    `${config.baseUrl}getTraininfo?&page=${1}&size=${9999}&startstatioinid=${depPlaceId}&arrstationid=${arrPlaceId}&searchdate=${depPlandTime}&traincode=${traincode}`
  );
}
// 역조회
function trainlistdep(cityCode) {
  return axios.get(
    `${config.baseUrl}TrainStationListByCityid?cityid=${cityCode}`
  );
}
function trainlistarr(cityCode) {
  return axios.get(
    `${config.baseUrl}TrainStationListByCityid?cityid=${cityCode}`
  );
}

//진구 가맹점 리스트
function jinguFranchiseList(page, size, code, categoryid, searchtext) {
  return axios.get(
    `${config.baseUrl}jingulist?page=${page}&size=${size}&code=${code}&categoryid=${categoryid}&searchtext=${searchtext}`
  );
}
function jinguFranchiseInfo(phonenumber,nationCode) {
  return axios.get(`${config.baseUrl}jinguinfobygroup?groupname=${phonenumber}&code=${nationCode}`).then((item)=>{
    item.data.data.image = setAddImageList(item.data.data)
    console.log(item.data)
    return item.data}).catch(e =>{

    });
}
// 조회수
function jinguFranchiseCount(id, nationcode) {
  return axios.get(
    `${config.baseUrl}jinguinfo/updateviewcount?id=${id}&nationcode=${nationcode}`
  );
}
//외국유치기관 리스트
function forlist(page, size, code, categoryid, searchtext) {
  return axios.get(
    `${config.baseUrl}forlist?page=${page}&size=${size}&code=${code}&categoryid=${categoryid}&searchtext=${searchtext}`
  );
}
//외국유치기관 정보
function forInfo(phonenumber,nationCode) {
  return axios.get(
    `${config.baseUrl}forinfobygroup?groupname=${phonenumber}&code=${nationCode}`).then((item)=>{
    item.data.data.image = setAddImageList(item.data.data)
    return item.data}).catch(e =>{

    });
}
// 조회수
function forCount(id, nationcode) {
  return axios.get(
    `${config.baseUrl}forinfo/updateviewcount?id=${id}&nationcode=${nationcode}`
  );
}

// ktx, srt역조회
function trainlist(page, size, code, ktxsrt) {
  return axios.get(
    `${config.baseUrl}TrainStationListSearch?page=${page}&size=${size}&code=${code}&ktxsrt=${ktxsrt}`
  );
}

//ai 추천코스 카테고리 조회
function aiCategorylist(page, size, code, categoryid) {
  return axios.get(
    `${config.baseUrl}ai/categorylist?page=${page}&size=${size}&code=${code}&categoryid=${categoryid}`
  );
}
// ai 추천코스 list
function aiCourselist(page, size, code, nation, season, age, medicalkindof) {
  season = ''
  nation = ''
  // age = ''
  medicalkindof = ''
  return axios.get(
    `${config.baseUrl}ai/aicourselist?page=${page}&size=${size}&code=${code}&nation=${nation}&season=${season}&age=${age}&medicalkindof=${medicalkindof}`
  );
}
//ai 추천코스 상세보기
function aiCourseinfo(uid,nationCode) {
  return axios.get(
    `${config.baseUrl}ai/aicourseinfobygroup?groupname=${uid}&code=${nationCode}`
  ).then((item)=>{
    item.data.data.image = setAddImageList(item.data.data)
    return item.data}).catch(e =>{

    });
}

function tourCount(id, nationcode) {
  return axios.get(
    `${config.baseUrl}tour/updateviewcount?id=${id}&nationcode=${nationcode}`
  );
}

export {
  banner,
  totalsearch,
  mainstatics,
  mainnotice,
  notice,
  noticeinfo,
  noticecount,
  tourlist,
  tourinfo,
  medicalList,
  medicalinfo,
  medicalcount,
  eBook,
  eBookinfo,
  eBookcount,
  faqList,
  categoryinfo,
  airinfo,
  airsearch,
  airsearcharr,
  traininfo,
  trainlistdep,
  trainlistarr,
  detectlang,
  translate,
  jinguFranchiseCount,
  jinguFranchiseInfo,
  jinguFranchiseList,
  forlist,
  forInfo,
  forCount,
  trainlist,
  aiCategorylist,
  aiCourselist,
  aiCourseinfo,
  tourCount
};
