<template>
  <div>
    <div id="skip_nav">
      <a href="#gnb">메뉴 바로가기</a>
      <a href="#content">본문 바로가기</a>
    </div>
    <header id="header">
      <div class="dim" @click="closeGnb()">&nbsp;</div>
      <div class="main_header">
        <!-- 로고 다국어 처리 전 원본코드. -->
        <!-- <h1><router-link to="/main">부산 진구 의료관광서비스</router-link></h1> -->
        <div class="main_global_logo" v-if="$store.state.nation.Code === 'ko'">
          <router-link to="/main"
            ><img
              src="../assets/images/logo_ko.png"
              alt="부산 진구 의료관광서비스ko"
          /></router-link>
        </div>
        <div
          class="main_global_logo"
          v-else-if="$store.state.nation.Code === 'en'"
        >
          <router-link to="/main"
            ><img
              src="../assets/images/logo_en.png"
              alt="부산 진구 의료관광서비스en"
          /></router-link>
        </div>
        <div
          class="main_global_logo"
          v-else-if="$store.state.nation.Code === 'jp'"
        >
          <router-link to="/main"
            ><img
              src="../assets/images/logo_jp.png"
              alt="부산 진구 의료관광서비스jp"
          /></router-link>
        </div>
        <div
          class="main_global_logo"
          v-else-if="$store.state.nation.Code === 'chs'"
        >
          <router-link to="/main"
            ><img
              src="../assets/images/logo_chs.png"
              alt="부산 진구 의료관광서비스chs"
          /></router-link>
        </div>
        <div
          class="main_global_logo"
          v-else-if="$store.state.nation.Code === 'cht'"
        >
          <router-link to="/main"
            ><img
              src="../assets/images/logo_cht.png"
              alt="부산 진구 의료관광서비스cht"
          /></router-link>
        </div>
        <div v-else>
          <router-link to="/main"
            ><img src="../assets/images/logo.png" alt="부산 진구 의료관광서비스"
          /></router-link>
        </div>
        <div class="search_container">
          <input
            type="text"
            name=""
            id=""
            :placeholder="$t('placeHolder.type0')"
            v-model="searchtext"
            @keyup.enter="search"
          />
          <a class="btn_top_search_open" @click="search">검색</a>
        </div>
        <button class="btn_top_gnb_open" @click="openGnb()">메뉴 열기</button>
      </div>
      <div class="sub_header">
        <div class="lang-container">
          <router-link to="/eBook" class="e-book" @click="closeMenu"
            >{{ $t("nav.eBook") }}
            <img src="../assets/images/download_icon.png" alt="다운로드아이콘"
          /></router-link>
          <label class="lang-kor"
            ><input
              :key="$route.fullPath"
              value="ko"
              @click="changeLocale($event.target.value)"
              type="button"
            />KOR</label
          >
          <label class="lang-eng"
            ><input
              value="en"
              @click="changeLocale($event.target.value)"
              type="button"
            />ENG</label
          >
          <label class="lang-jpn"
            ><input
              value="jp"
              @click="changeLocale($event.target.value)"
              type="button"
            />JPN</label
          >
          <label class="lang-chi"
            ><input
              value="chs"
              @click="changeLocale($event.target.value)"
              type="button"
            />CHS</label
          >
          <label class="lang-chi"
            ><input
              value="cht"
              @click="changeLocale($event.target.value)"
              type="button"
            />CHT</label
          >
          <button class="btn_top_gnb_close" @click="closeGnb()">닫기</button>
        </div>
        <div class="nav_container">
          <nav id="gnb">
            <ul>
              <li class="present">
                <a>{{ $t("nav.nav0") }}</a>
                <ul class="depth">
                    <li>
                      <router-link
                        to="/foreign_medical_institution_information_list"
                        >{{ $t("nav.dropNav.nav0.n3") }}</router-link
                      >
                    </li><!--외국인 환자 유치기관-->
                    <li>
                      <router-link to="/medical_institution_information_list">{{
                        $t("nav.dropNav.nav0.n0")
                      }}</router-link>
                    </li><!--일반 병의원-->
                    <li>
                      <router-link to="/jingu_franchise_list">{{
                        $t("nav.dropNav.nav0.n2")
                      }}</router-link>
                    </li><!--진구 가맹점 정보-->
                    <li>
                      <router-link to="/tourist_information_list">{{
                        $t("nav.dropNav.nav0.n1")
                      }}</router-link>
                    </li><!--관광정보-->
                </ul>
              </li>
              <li class="information">
                <a>{{ $t("nav.nav1") }}</a>
                <ul class="depth">
                  <li>
                    <router-link to="/refund_application_info">{{
                      $t("nav.dropNav.nav1.n0")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/emergency_response">{{
                      $t("nav.dropNav.nav1.n1")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/duty_free">{{
                      $t("nav.dropNav.nav1.n2")
                    }}</router-link>
                  </li>
                </ul>
              </li>
              <li class="guide">
                <a>{{ $t("nav.nav2") }}</a>
                <ul class="depth">
                  <li>
                    <router-link to="/location_based">{{
                      $t("nav.dropNav.nav2.n0")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/route_search">{{
                      $t("nav.dropNav.nav2.n1")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/subway_map">{{
                      $t("nav.dropNav.nav2.n2")
                    }}</router-link>
                  </li>
                </ul>
              </li>
              <li class="notify">
                <a>{{ $t("nav.nav3") }}</a>
                <ul class="depth">
                  <li>
                    <router-link to="/ai_translation">{{
                      $t("nav.dropNav.nav3.n0")
                    }}</router-link>
                  </li>
                  <!-- <li>
                    <router-link to="/ready_page5">{{
                      $t("nav.dropNav.nav3.n1")
                    }}</router-link>
                  </li> -->
                  <li>
                    <router-link to="/ai_travel_recommendation">{{
                      $t("nav.dropNav.nav3.n1")
                    }}</router-link>
                  </li>
                </ul>
              </li>
              <li class="participation">
                <a>{{ $t("nav.nav4") }}</a>
                <ul class="depth">
                  <li>
                    <router-link to="/air_traffic_info">{{
                      $t("nav.dropNav.nav4.n0")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/train_service_info">{{
                      $t("nav.dropNav.nav4.n1")
                    }}</router-link>
                  </li>
                </ul>
              </li>
              <li class="participation">
                <a>{{ $t("nav.nav5") }}</a>
                <ul class="depth">
                  <li>
                    <router-link to="/notice">{{
                      $t("nav.dropNav.nav5.n0")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/faq">{{
                      $t("nav.dropNav.nav5.n1")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/ebook">{{
                      $t("nav.dropNav.nav5.n2")
                    }}</router-link>
                  </li>
                </ul>
              </li>
              <li class="participation">
                <a>{{ $t("nav.nav6") }}</a>
                <ul class="depth">
                  <li>
                    <router-link to="/smart_intro">{{
                      $t("nav.dropNav.nav6.n0")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/smart_platform">{{
                      $t("nav.dropNav.nav6.n1")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/smart_wifi">{{
                      $t("nav.dropNav.nav6.n2")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/smart_kiosk">{{
                      $t("nav.dropNav.nav6.n3")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/smart_carriage">{{
                      $t("nav.dropNav.nav6.n4")
                    }}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { i18n } from "@/locales/i18n";
export default {
  data() {
    return {
      searchtext: "",
      nationcode: [{ ko: "ko", jp: "jp", en: "en", chs: "chs", cht: "cht" }],
    };
  },
  methods: {
    closeMenu() {},
    mainLoad() {
      this.$store
        .dispatch("MAIN_STATICS", { code: this.$store.state.nation.Code })
        .then(() => {
          this.mainstatics = this.$store.state.mainstatics;
        });
      this.$store.dispatch("BANNER", { code: this.$store.state.nation.Code });
      this.$store
        .dispatch("MAIN_NOTICE", {
          page: 1,
          code: this.$store.state.nation.Code,
          categoryid: this.$store.state.Category,
        })
        .then(() => {
          this.item = this.$store.state.mainnotice.data.currentlist.noticelist;
        });
    },
    changeLocale(value) {
      this.$store.state.nation.Code = value;
      this.mainLoad();
      //this.$router.push(`/main?${value}`);
      i18n.locale = value;
      this.closeGnb();
      setTimeout(() => { this.reload() }, 50)
    },
    reload() {
      return window.location.reload(true);
    },
    search() {
      if (this.searchtext == "") {
        alert(this.$i18n.t("alert.search"));
      } else {
        this.$store.state.Searchtext = this.searchtext;
        this.$store.dispatch("TOTAL_SEARCH", {
          searchtext: this.searchtext,
        });
        this.$router.push({
          path: "/search_result",
          query: { searchtext: this.searchtext },
        });
      }
    },
    // search(searchtext) {
    //   if (searchtext == '') {
    //     alert('검색어를 입력해 주세요.');
    //   } else {
    //     this.$store.state.Searchtext = searchtext;
    //     this.$store.dispatch('TOTAL_SEARCH', {
    //       searchtext: this.$store.state.Searchtext,
    //     });
    //     console.log(this.$route.query);
    //     this.$router.push({
    //       path: '/search_result',
    //       query: { searchtext: this.$store.state.Searchtext },
    //     });
    //   }
    // },
    openGnb() {
      const $gnbTarget = document.querySelectorAll(".sub_header, .dim");
      document.querySelector("body").classList.add("overflow-hidden");
      $gnbTarget.forEach((el) => {
        el.classList.add("active");
      });
    },
    closeGnb() {
      const $gnbTarget = document.querySelectorAll(".sub_header, .dim");
      $gnbTarget.forEach((el) => {
        el.classList.remove("active");
      });
      document.querySelector("body").classList.remove("overflow-hidden");
    },
  },
};
</script>

<style scoped>
#gnb > ul > li:last-child > a::after {
  transform: rotate(90deg);
}
.router-link-exact-active,
.router-link-active {
  color: #295caa !important;
}
.lang-container a {
  color: #fff !important;
}
</style>
